/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
.loader {
    border-top-color: #3498db;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

.dark .loader {
    border-color: #444 !important;
    border-top-color: #6e34db !important;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}
.loader3 {
    border-top-color: #3498db;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

.dark .loader3 {
    border-color: #444 !important;
    border-top-color: #6e34db !important;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.masonry {
    column-width: 140px;
    column-count: 4;
    column-gap: 16px;
    row-gap: 16px;
}


.masonry div {
    /* important so a single div never gets distributed between columns */
    break-inside: avoid-column;

    /* background-color: #2C2F33; */
    /* margin-bottom: 16px; */
    /* color:white; */
}

.masonry-3-cols {
    column-width: 140px;
    column-count: 3;
    column-gap: 16px;
    row-gap: 24px;

}


.masonry-3-cols div {
    /* important so a single div never gets distributed between columns */
    break-inside: avoid-column;

    /* background-color: #2C2F33; */
    /* margin-bottom: 16px; */
    /* color:white; */
}

html {
    /* overscroll-behavior-y: none; */
    overflow-x: hidden;
    max-height: 100vh;
    max-width: 100%;
    width: 100vw;
    font-family: Gilroy-Regular, sans-serif;
}

body {
    /* overscroll-behavior-y: none; */
    min-height: 100%;
    overflow-x: hidden;
    /* position: relative; */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #cbd5e1;
}

body.dark-theme {
    background-color: #0f172a;
    color: #fff;
}
body>#root {
    width: 100vw;
    max-height: 100vh;
    /* overflow-y: auto; */
    -webkit-overflow-scrolling: touch;
    /* enables “momentum” (smooth) scrolling */
}

.inIframe {
    background-color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
/* .card {
    @apply bg-white rounded-lg p-5 dark:bg-gray-700;
  }

.table {
    @apply min-w-full divide-y divide-gray-200 overflow-x-hidden w-full
}

.table th {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider
}

.table td {
    @apply px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900
}

.table-responsive {
    @apply shadow overflow-scroll border-b border-gray-200 sm:rounded-lg w-full
} */

@supports (padding-top: constant(safe-area-inset-top)) {
    body {
        padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    }
}

@media (display-mode: fullscreen) {
    body {
        padding: 0;
    }
}

@media (display-mode: standalone) {
    body {
        padding: 0;
    }
}

/* Older browsers (which don't support CSS variables) */
body {
    height: 100%;
}

/* Browsers which partially support CSS variables (iOS 11.0 – 11.2) */
@supports (padding-top: constant(safe-area-inset-top)) {
    body {
        --safe-area-inset-top: constant(safe-area-inset-top);
        height: calc(100% + var(--safe-area-inset-top));
    }
}

/* Adapted from https://medium.com/@draganeror/iphone-x-layout-features-with-css-environment-variables-d57423433dec */
/* Browsers which fully support CSS variables (iOS 11.2+). */
@supports (padding-top: env(safe-area-inset-top)) {
    body {
        --safe-area-inset-top: env(safe-area-inset-top);
        height: calc(100% + var(--safe-area-inset-top));
    }
}

@media (display-mode: fullscreen) {
    body {
        height: 100%;
    }
}

@media (display-mode: standalone) {
    body {
        height: 100%;
    }
}

body {
    padding:
        env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px);
}


.darken-filter {
    filter: brightness(0.5);
}

.dark svg {
    /* filter: brightness(0.7)   ;; */
    opacity: 0.6;
}

html {
    color: #555;
}

html .dark-theme {
    color: #aaa;
}